<template></template>

<script>
import reloadSite from "@/utils/mixins/reloadSite";

export default {
  mixins: [reloadSite],
  name: "LogoutCallback",
  created() {
    this.$store.commit("resetSession");
    this.reloadSite("login");
    console.log(">>> logout callback ...");
  },
};
</script>
